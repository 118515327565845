import React from 'react';
import moment from 'moment';
import get from 'lodash/get';
import Img from 'gatsby-image';
import richTextParser from '../../utils/rich-text-parser';

import styles from './news.module.css';

function News(props) {
  const news = get(props, 'data');
  const locale = get(props, 'locale').split('-')[0]; // en-US -> en
  const momentParams = {
    months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
  };
  moment.locale(locale, locale === 'fr' ? momentParams : {});
  return (
    <div className="testNews">
      <Img alt={news.image.id} fluid={news.image.fluid} />

      <h1 className={styles.h1}>
        <span>{moment(news.date).format('DD MMMM YYYY')}</span>
        <span>-</span>
        <span>{news.statut}</span>
      </h1>
      <h2 className={styles.h2}>{news.titre}</h2>

      {richTextParser(news.contenu.json)}
    </div>
  );
}

export default News;
