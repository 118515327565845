import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import get from 'lodash/get';
import Layout from '../../components/layout';
import News from '../../components/news';
import { LanguageContext } from '../../context';

import styles from './newsList.module.css';

function NewsListTemplate(props) {
  const news = get(props, 'data.allContentfulNews');
  const location = get(props, 'location');

  return (
    <Layout location={location}>
      <LanguageContext.Consumer>
        {(localeFromReactContext) => {
          return (
            <>
              <Helmet title={localeFromReactContext === 'fr' ? 'Actualités' : 'News'} />
              <div className={styles.gridWrapper}>
                {news.edges.map(({ node }) => {
                  return (
                    <div key={node.slug}>
                      <News data={node} locale={localeFromReactContext} />
                    </div>
                  );
                })}
              </div>
            </>
          );
        }}
      </LanguageContext.Consumer>
    </Layout>
  );
}

export default NewsListTemplate;

export const pageQuery = graphql`
  query HomeQuery($locale: String!) {
    allContentfulNews(filter: { node_locale: { eq: $locale } }, sort: { fields: [date], order: DESC }) {
      edges {
        node {
          titre
          slug
          node_locale
          image {
            id
            description
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          contenu {
            json
          }
          date
          statut
        }
      }
    }
  }
`;
